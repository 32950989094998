<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('my.sys_notice')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="content">
		<div style="font-weight: 500; font-size: 24px;">{{notice.name}}</div>
      <!-- Buttons to switch content -->
      <div class="buttons">
        <button @click="currentContent = 'content1'"> 
		<img src="img/ico-info.31a6afcb.png" style="display: inline-block;
    width: 3vw; color: red;">info </button>
        <button @click="currentContent = 'content2'">images(8)</button>
       <!-- <button @click="currentContent = 'content3'">reprot(18)</button> -->
      </div>

      <!-- Dynamic content based on the currentContent value -->
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="currentContent === 'content1'" class="listItem">
          <div class="listTitle "><img :src="notice.img" style="width: 100%;"></div>
<div class="listTitle tys" ><img src="img/ico-love.e61811a3.png" alt="" class="movie-list-ico-loves"><img src="img/ico-love.e61811a3.png" alt="" class="movie-list-ico-loves"><img src="img/ico-love.e61811a3.png" alt="" class="movie-list-ico-loves"><img src="img/ico-love.e61811a3.png" alt="" class="movie-list-ico-loves"><img src="img/ico-love.e61811a3.png" alt="" class="movie-list-ico-loves"></div>

<div class="tys">
		<img src="img/qb.png" class="movie-list-ico-loves">
		{{notice.price}}
	</div>
	<div class="tys">
		<img src="img/ico-addr.95fc5c8d.png"  class="movie-list-ico-loves">
		{{notice.city_name}}
	</div> 
<div class="tys">
	<img src="/img/ico-time.8c3feaf4.png"  class="movie-list-ico-loves">
		{{notice.birthday}}
	</div>
	
	
	<div class="single-row">nHome.specifications</div>
	<div class="row">
		<div class="column">Giới thiệu</div>
		<div class="column">{{ notice.attributes[14] }}</div>
	</div>
	
	<div class="row dd" >
		<div class="column">Pass</div>
		<div class="column">{{ notice.attributes[15] }}</div>
	</div>
	
	<div class="row">
		<div class="column">Năm sinh</div>
		<div class="column">{{ notice.attributes[0] }}</div>
	</div>

	<div class="row dd">
		<div class="column">Cao (cm)</div>
		<div class="column">{{ notice.attributes[1] }}</div>
	</div>
	
	<div class="row">
		<div class="column">Nặng (kg)</div>
		<div class="column">{{ notice.attributes[3] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Vòng 1 (cm)</div>
		<div class="column">{{ notice.attributes[4] }}</div>
	</div>
	<div class="row">
		<div class="column">Vòng 2 (cm)</div>
		<div class="column">{{ notice.attributes[5] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Vòng 3 (cm)</div>
		<div class="column">{{ notice.attributes[6] }}</div>
	</div>

	<div class="row">
		<div class="column">Xuất xứ</div>
		<div class="column">{{ notice.attributes[12] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Lông bím</div>
		<div class="column">{{ notice.attributes[13] }}</div>
	</div>
	<div class="row">
		<div class="column">Mặt</div>
		<div class="column">{{ notice.attributes[11] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Service</div>
		<div class="column">{{ notice.attributes[10] }}</div>
	</div>
	<div class="row">
		<div class="column">Phụ phí</div>
		<div class="column">{{ notice.attributes[16] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Nhận dạng</div>
		<div class="column">{{ notice.attributes[2] }}</div>
	</div>
	<div class="row">
		<div class="column">Thời lượng</div>
		<div class="column">{{ notice.attributes[7] }}</div>
	</div>
	<div class="row dd">
		<div class="column">Hoạt động</div>
		<div class="column">{{ notice.attributes[8] }}</div>
	</div>
	<div class="row">
		<div class="column">Từ chối</div>
		<div class="column">{{ notice.attributes[9] }}</div>
	</div>
	
        </div>
        <div v-if="currentContent === 'content2'" class="listItem">
          <div class="listTitle"><div v-for="(url, index) in notice.img_url" :key="index" class="image-container"><img class="daxiao" :src="url" alt="Image" /> </div></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      notice: {},
      loading: false,
      finished: false,
      currentContent: 'content1'  // 默认显示内容 1
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getNoticeList() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'jingxuan_get_info'
      }).then(res => {
        console.log(res.data);
        this.notice = res.data;
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
        this.getNoticeList();
      }, 500);
    },
  },
  created() {
    this.getNoticeList();
  }
};
</script>

<style lang='less' scoped>
.image-container {
  margin: 10px;
}

.daxiao {
  width: 50%;
 float: left;
 padding: 1.333vw;
 display: block;
    width: 100%;
     height: 100%;
/*  object-fit: cover; */
}
	
	/* 单独一行的样式 */
.single-row {
  background-color: #ccc;
  padding: 20px;
 
  border: 1px solid #ddd;
  margin-bottom: 20px; /* 与下面的列分开 */
}

/* 一行两列的样式 */
.row {
  display: flex;
  justify-content: space-between;
   background-color: #f0f0f0;
}

.column {
 /* flex: 1; */
 /* margin: 0 10px; */
 
  padding: 3.667vw;
  
 
 /* border: 1px solid #ddd; */
}
.tys{
/* display: flex; */
flex-direction: row;
flex-wrap: nowrap;
margin-left: 25px;

}
.movie-list-ico-loves{
    display: inline-block;
    width: 5.333vw;
}
.buttons {
  margin: 10px;
  display: flex;
  justify-content: space-around;
}

.buttons button {
  padding: 10px;
  font-size: 26px;
  border: 0px;
  /* background-color: white; */
  color: #f905e5;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.buttons button.active {
color: red;
 
}

.buttons button:hover {
 
  color: red; /* 鼠标悬停的文字颜色 */
}

.dd{
		background-color: #999;
	}
</style>
